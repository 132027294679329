<script>
import appConfig from "../../../../src/app.config";
import axios from 'axios';

import Layout from "@/router/layouts/main";
import { mapState } from "vuex";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";
import Upload from "@/components/widgets/upload-clientes";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
    VclList,
    Upload,
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  data() {
    return {
      loading: true,
      errored: false,
      fields: [
        { key: "id", label: "" },
        { key: "date", label: "Data", sortable: true, },
        { key: "total", label: "Total", sortable: true, },
        { key: "status", label: "Status", sortable: true, },
        { key: "url", label: "Arquivo", },
      ],
      items: [],

      del: {
        modal: false,
        id: null,
      },
    };
  },
  methods: {
    getFiles() {
      this.loading = true;

      api.get("clientes/importar").then((res) => {
        if (res.data.status == "success") {
          this.items = res.data.list;
        }
      })
        .catch((error) => {
          this.errored = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showDelete(id) {
      this.del.modal = true;
      this.del.id = id;
    },
    delFile() {
      if (this.del.id) {
        this.del.modal = false;

        api.delete("clientes/importar/" + this.del.id).then((res) => {
          if (res.data.status == "success") {
            this.getFiles();
            this.$toast.success('O arquivo foi excluído com sucesso.');
          }
        })
      }
    },
    downloadFile(id) {
      axios({
        url: appConfig.apiUrl + "clientes/importar/" + id + "/download",
        method: 'GET',
        responseType: 'blob',
        headers: {
          "x-auth-token": localStorage.token,
        }
      }).then(res => {
        const fileURL = window.URL.createObjectURL(new Blob([res.data]));
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', id + '.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
        window.URL.revokeObjectURL(fileURL);
      }).catch(error => {
        if (error) {
          this.$toast.error('Erro durante o download do arquivo.');
        }
      });
    }
  },
  mounted() {
    this.getFiles();
  },
};
</script>

<template>
  <Layout>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item"><router-link tag="a" to="/clientes">Clientes</router-link></li>
      <li class="breadcrumb-item d-none d-sm-block">Importar clientes</li>
    </ol>

    <div class="page-title-box d-flex justify-content-between mb-3">
      <div>
        <h3>Importar clientes</h3>
        <p>Envie um arquivo .xls para importação em massa dos clientes.</p>
      </div>
    </div>

    <div class="mb-3">
      <a href="/downloads/clientes.xlsx" class="btn btn-default mr-2">
        <i class="bx bx-download font-size-18 align-top mr-2"></i>
        Download da planilha de exemplo
      </a>
    </div>

    <div class="card">
      <div class="card-body">
        <Upload></Upload>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <strong>Últimas importações</strong>
      </div>
      <div class="card-body">
        <div v-if="errored">
          Pedimos desculpas, não podemos recuperar informações neste momento. Por favor, tente novamente mais tarde.
        </div>
        <vcl-list v-else-if="loading" class="col-md-6"></vcl-list>
        <div v-else-if="items && items.length == 0" class="text-center">
          Nenhum arquivo encontrado.
        </div>
        <div v-else class="table-responsive">
          <b-table :items="items" :fields="fields" class="table-nowrap table-style table-hover" head-variant="light" responsive>
            <template #cell(id)="row">
              <b-dropdown variant="more">
                <template #button-content>
                  <i class="fas fa-ellipsis-h"></i>
                </template>
                <b-dropdown-item :to="'/clientes/importar/' + row.item.id"><i class="bx bx-pencil"></i>
                  ver arquivo</b-dropdown-item>
                <b-dropdown-item @click="showDelete(row.item.id)"><i class="bx bx-trash"></i> excluir</b-dropdown-item>
              </b-dropdown>
            </template>
            <template #cell(url)="row">
              <button class="btn btn-link" v-on:click="downloadFile(row.item.id)"><i class="bx bx-download font-size-20 align-middle"></i></button>
            </template>
            <template #cell(status)="row">
              <span v-if="row.item.status == 'pending'" class="bg-warning rounded px-1 font-size-1 text-uppercase text-white">Pendente</span>
              <span v-else class="bg-success rounded px-1 font-size-1 text-uppercase text-white">Processado</span>
            </template>
          </b-table>
        </div>
      </div>
    </div>

    <b-modal v-model="del.modal" modal-class="modal-bottom" hide-header hide-footer>
      <div>
        <h4>Excluir arquivo</h4>
        <p>Confirma exclusão do arquivo?</p>
        <div class="d-flex">
          <button class="btn btn-default mr-3" v-on:click="delFile">Confirmar</button>
          <button class="btn btn-outline-link" v-on:click="del.modal = false">Fechar <sup>ESC</sup></button>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>